export type TMarketingBucketsValues = {
    staging: string;
    production: string;
    defaultBranch?: string;
};

export type TAlgoliaConfigValues = {
    appId: string;
    appKey: string;
};

export type TOktaConfigValues = {
    clientId: string;
    issuer: string;
    pkce: boolean;
    OKTA_ENABLED: boolean;
};

type TPathsValues =
    | TMarketingBucketsValues
    | TAlgoliaConfigValues
    | TOktaConfigValues
    | string;

type TPathsOutput = {
    API: TPathsValues;
    GRAPH_VARIANT: TPathsValues;
    FORGE_URL_TEMPLATE: TPathsValues;
    ALGOLIA_CONFIG: TPathsValues;
    OKTA_CONFIG: TPathsValues;
    FORGE_URL_VSM_CONTENTS_BASE: TPathsValues;
};

export const getPathsByAppEnv = (): TPathsOutput => {
    // This will use webpack tree shaking to eliminate the config for other environments in production
    switch (process.env.REACT_APP_ENV) {
        case "development": {
            return {
                API: "https://fivetool-preview.mlbinfra.net/api",
                GRAPH_VARIANT: "qa",
                FORGE_URL_TEMPLATE: "https://forge[PROPERTY]-qa.mlbinfra.com",
                FORGE_URL_VSM_CONTENTS_BASE: "https://forge-qa.mlbinfra.com/#/wcm/custom/vsmcontent/",
                ALGOLIA_CONFIG: {
                    appId: "testingRYJGERUSLM",
                    appKey: "c009d6d1098357e6907435cf9e50c1a9",
                },
                OKTA_CONFIG: {
                    clientId: "0oawot1ly6YCg0QEP1t7",
                    issuer: "https://mlbtest.okta.com/oauth2/auswot85qiNATDnGV1t7",
                    pkce: false,
                    OKTA_ENABLED: true,
                },
            };
        }
        case "qa": {
            return {
                API: "https://fivetool-qa.mlbinfra.net/api",
                GRAPH_VARIANT: "qa",
                FORGE_URL_TEMPLATE: "https://forge[PROPERTY]-qa.mlbinfra.com",
                FORGE_URL_VSM_CONTENTS_BASE: "https://forge-qa.mlbinfra.com/#/wcm/custom/vsmcontent/",
                ALGOLIA_CONFIG: {
                    appId: "testingRYJGERUSLM",
                    appKey: "c009d6d1098357e6907435cf9e50c1a9",
                },
                OKTA_CONFIG: {
                    clientId: "0oawot1ly6YCg0QEP1t7",
                    issuer: "https://mlbtest.okta.com/oauth2/auswot85qiNATDnGV1t7",
                    pkce: false,
                    OKTA_ENABLED: true,
                },
            };
        }
        case "production": {
            return {
                API: "https://fivetool.mlbinfra.com/api",
                GRAPH_VARIANT: "production",
                FORGE_URL_TEMPLATE: "https://forge[PROPERTY].mlbinfra.com",
                FORGE_URL_VSM_CONTENTS_BASE: "https://forge.mlbinfra.com/#/wcm/custom/vsmcontent/",
                ALGOLIA_CONFIG: {
                    appId: "YVO49OXZY7",
                    appKey: "3e32626cb6aae7941617a1be0ac58bfe",
                },
                OKTA_CONFIG: {
                    clientId: "0oareh6e9oJYJDrag0x7",
                    issuer: "https://mlb.okta.com/oauth2/ausvg8mpifcbOzLyQ0x7",
                    pkce: false,
                    OKTA_ENABLED: true,
                },
            };
        }
        default: {
            return {
                API: "http://localhost:80/api",
                GRAPH_VARIANT: "qa",
                FORGE_URL_TEMPLATE: "https://forge[PROPERTY]-qa.mlbinfra.com",
                FORGE_URL_VSM_CONTENTS_BASE: "https://forge-qa.mlbinfra.com/#/wcm/custom/vsmcontent/",
                ALGOLIA_CONFIG: {
                    appId: "testingRYJGERUSLM",
                    appKey: "c009d6d1098357e6907435cf9e50c1a9",
                },
                OKTA_CONFIG: {
                    clientId: "0oawot1ly6YCg0QEP1t7",
                    issuer: "https://mlbtest.okta.com/oauth2/auswot85qiNATDnGV1t7",
                    pkce: false,
                    OKTA_ENABLED: false,
                },
            };
        }
    }
};

export default getPathsByAppEnv();
