export function appReducer(state, action) {
    switch (action.type) {
        case "SET_USER_INFO":
            return {
                ...state,
                ...action.payload,
            };
        case "SET_TEAMS_LIST":
            return {
                ...state,
                ...action.payload,
            };
        case "ADD_TOAST":
            return {
                ...state,
                toasts: [...state.toasts, action.payload],
            };
        case "RESET_TOAST":
            return {
                ...state,
                toasts: state.toasts.filter((_, i) => i !== action.payload),
            };
        case "RESET":
            return { teamsList: null, userInfo: undefined };
        default:
            return state;
    }
}
