import { OktaAuth } from "@okta/okta-auth-js";

import PATHS, { TOktaConfigValues } from "./configs/pathhelper";

const oktaConfig = PATHS.OKTA_CONFIG as TOktaConfigValues;

export const oktaAuth = new OktaAuth({
    issuer: oktaConfig.issuer,
    clientId: oktaConfig.clientId,
    redirectUri: `${window.location.origin}/login/callback`,
    pkce: oktaConfig.pkce,
});
