import styled from "styled-components";
import { Link } from "react-router-dom";

import { MAX_APP_WIDTH, APP_PERCENTAGE_WIDTH } from "../../constants";

export const SHeaderContainer = styled.header`
    align-items: center;
    background-color: #051e42;
    color: #ffffff;
    display: flex;
    height: 80px;

    img {
        height: 36px;
    }
`;

export const STestHeaderContainer = styled.header`
    align-items: center;
    background-color: #335B39;
    color: #ffffff;
    display: flex;
    height: 80px;

    img {
        height: 36px;
    }
`;

export const SHeaderInnerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${APP_PERCENTAGE_WIDTH}%;
    max-width: ${MAX_APP_WIDTH}px;
    margin: auto;
`;

export const SHeaderLogoNameContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const SLogoContainer = styled.div`
    height: 32px;
    font-size: 24px;
    padding: 0 16px 0 16px;
    text-decoration: none;
    color: #ffffff;

    &:visited {
        text-decoration: none;
        color: #ffffff;
    }
`;

export const SUnstyledLink = styled(Link)`
    text-decoration: none;
    color: #ffffff;
`;

export const SSignOutButton = styled.button`
    background-color: transparent;
    border: 0px;
    color: #ffffff;
    font-family: mlb-primary;
    font-size: 14px;
    cursor: pointer;
`;
