import { createGlobalStyle } from "styled-components";
import QaBackground from "./assets/fivetool-qa-bg.png";
const getGlobalStyles = (isTestEnv: boolean) => {
    const baseStyles = `
    @font-face {
        font-family: mlb-primary;
        src: url('/proxima-nova-regular.woff2');
        font-weight: 400;
    }
    @font-face {
        font-family: mlb-primary;
        src: url('/proxima-nova-medium.woff2');
        font-weight: 500;
    }
    @font-face {
        font-family: mlb-primary;
        src: url('/proxima-nova-semibold.woff2');
        font-weight: 600;
    }
    @font-face {
        font-family: mlb-primary;
        src: url('/proxima-nova-bold.woff2');
        font-weight: 700;
    }
    @font-face {
        font-family: mlb-primary;
        src: url('/proxima-nova-extrabold.woff2');
        font-weight: 800;
    }
  `;

    return createGlobalStyle`
    ${baseStyles}

    body {
        margin: 0;
        ${
            isTestEnv
                ? `background-image: url(${QaBackground});`
                : "background-color: #ffffff;"
        }
        color: #222222;
        font-family: mlb-primary;
    }
  `;
};

export const GlobalStyles = (isTestEnv: boolean) => getGlobalStyles(isTestEnv);
