import React from "react";

import { SWrapper, SHeading1, SHeading2 } from "./styles";

function Unauthorized() {
    return (
        <SWrapper>
            <SHeading1>Uh oh!</SHeading1>
            <SHeading2>
                Looks like you are unauthorized to access this page.
            </SHeading2>
        </SWrapper>
    );
}

export default Unauthorized;
