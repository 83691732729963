import { lazy, Suspense } from "react";
import { Route, Switch, useLocation, useHistory } from "react-router-dom";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";

import Header from "./components/Header";
import GlobalToast from "./components/Toast";
import { AppProvider } from "./context/AppContext";
import { GlobalStyles } from "./GlobalStyles";
import { AppContainer } from "./styles";
import { oktaAuth } from "./okta";
import AppAuthorizationWrapper from "./components/AppAuthorizationWrapper";
import Unauthorized from "./components/Unauthorized";
import { apps } from "./configs/apps";
import { isTestEnv } from "./utils";

const Home = lazy(() => import("./pages/Home"));
const Health = lazy(() => import("./pages/Health"));

const NotFound = lazy(() => import("./pages/NotFound"));

const ComponentDemo = lazy(() => import("./pages/ComponentDemo"));

const testEnv = isTestEnv();
const StyledApp = GlobalStyles(testEnv);

const App = () => {
    const { pathname } = useLocation();
    const isHealthPage = pathname === "/health";
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <AppProvider oktaAuth={oktaAuth}>
                <AppContainer>
                    <StyledApp />
                    {!isHealthPage && <Header isTestEnv={testEnv} />}
                    <GlobalToast.Container />
                    <Suspense fallback={null}>
                        <Switch>
                            {/* Authenticated Routes */}
                            {apps.map(({ enabled, page: Page, roles, url }) => (
                                <SecureRoute path={url} key={url}>
                                    <AppAuthorizationWrapper
                                        unauthorizedComponent={Unauthorized}
                                        permittedRoles={roles}
                                        appEnabled={enabled}
                                    >
                                        <Page key={window.location.pathname} />
                                    </AppAuthorizationWrapper>
                                </SecureRoute>
                            ))}
                            <Route exact path="/" component={Home} />
                            <Route path="/health" component={Health} />
                            <Route
                                path="/styleguide"
                                component={ComponentDemo}
                            />
                            <Route
                                path="/login/callback"
                                component={LoginCallback}
                            />
                            <Route path="*">
                                <NotFound />
                            </Route>
                        </Switch>
                    </Suspense>
                </AppContainer>
            </AppProvider>
        </Security>
    );
};

export default App;
