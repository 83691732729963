import { useOktaAuth } from "@okta/okta-react";
import { AppContextState } from "../context/AppContext";
import Role from "../constants/Role";

export function getAuthorizations(state: AppContextState, permittedRoles?: Role[]) {
    const access = {
        hasAdminAccess: false,
        hasRole: false,
        hasAppAccess: false,
    };

    const userGroups = state.userInfo && state.userInfo.groups ? (state.userInfo.groups as string[]) : null;

    if (!userGroups) {
        return access;
    }

    access.hasAdminAccess = userGroups.includes(Role.ADMIN);
    access.hasRole = permittedRoles && permittedRoles.some((role) => userGroups.includes(role));
    access.hasAppAccess = access.hasAdminAccess || access.hasRole;

    return access;
}

export function getOktaAccessToken() {
    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();
    return accessToken;
}
