const TOOLS = {
    REDIRECTS: "REDIRECTS",
    MARKETING: "MARKETING",
    FORGE: "FORGE",
    PLAYER_PHOTOS: "PLAYER_PHOTOS",
    ABTEST: "AB_TEST_REGISTRATION",
    VSM_REPORTING: "VSM_REPORTING",
    P2P_REDIRECTS: "P2P_REDIRECTS",
    BULK_PLAYER_PHOTOS: "BULK_PLAYER_PHOTOS",
    PLAYER_MUSIC: "PLAYER_MUSIC",
} as const;

const features = {
    [TOOLS.REDIRECTS]: {
        local: true,
        development: true,
        qa: true,
        production: true,
    },
    [TOOLS.MARKETING]: {
        local: true,
        development: true,
        qa: true,
        production: true,
    },
    [TOOLS.FORGE]: {
        local: false,
        development: false,
        qa: false,
        production: false,
    },
    [TOOLS.PLAYER_PHOTOS]: {
        local: true,
        development: true,
        qa: true,
        production: true,
    },
    [TOOLS.ABTEST]: {
        local: true,
        development: true,
        qa: true,
        production: true,
    },
    [TOOLS.VSM_REPORTING]: {
        local: true,
        development: true,
        qa: true,
        production: true,
    },
    [TOOLS.P2P_REDIRECTS]: {
        local: true,
        development: true,
        qa: true,
        production: true,
    },
    [TOOLS.BULK_PLAYER_PHOTOS]: {
        local: true,
        development: true,
        qa: true,
        production: true,
    },
    [TOOLS.PLAYER_MUSIC]: {
        local: true,
        development: true,
        qa: true,
        production: true,
    },
};

export type FeatureFlags = {
    [TOOLS.FORGE]: boolean;
    [TOOLS.MARKETING]: boolean;
    [TOOLS.PLAYER_PHOTOS]: boolean;
    [TOOLS.REDIRECTS]: boolean;
    [TOOLS.ABTEST]: boolean;
    [TOOLS.VSM_REPORTING]: boolean;
    [TOOLS.P2P_REDIRECTS]: boolean;
    [TOOLS.BULK_PLAYER_PHOTOS]: boolean;
    [TOOLS.PLAYER_MUSIC]: boolean;
};

const getFeaturesByEnv = () => {
    const ENV = process.env.REACT_APP_ENV;
    return Object.entries(features).reduce(
        (flags, [featureName, featureEnvFlags]) => {
            flags[featureName] = featureEnvFlags[ENV] || false;
            return flags;
        },
        {},
    ) as FeatureFlags;
};

export const FEATURES = getFeaturesByEnv();
