import { XIcon } from "@heroicons/react/outline";
import {
    InformationCircleIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
} from "@heroicons/react/solid";
import styled from "styled-components";

import { TToastType } from ".";

const SUCCESS_COLOR = "#198754";
const ERROR_COLOR = "#DC3545";
const INFO_COLOR = "#868686";

export const SToastContainer = styled.div<{ type: TToastType }>`
    display: flex;
    min-width: 271px;
    height: 48px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    margin-bottom: 8px;
    background-color: ${(props) => {
        if (props.type === "error") return ERROR_COLOR;
        if (props.type === "success") return SUCCESS_COLOR;
        if (props.type === "info") return INFO_COLOR;
    }};
`;

export const SToastInnerContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const SToastExitBtn = styled.button`
    display: flex;
    width: 32px;
    height: 32px;
    border: 0px;
    outline: none;
    border-radius: 4px;
    background-color: transparent;
    color: #ffffff;
    margin-right: 16px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

export const SToastExitIcon = styled(XIcon)`
    fill: #ffffff;
    width: 20px;
    height: 20px;
`;

export const SToastText = styled.span`
    color: #ffffff;
    font-family: mlb-primary;
    font-size: 13px;
`;

export const SToastInfoIcon = styled(InformationCircleIcon)`
    fill: #ffffff;
    width: 20px;
    height: 20px;
    margin-left: 16px;
    margin-right: 12px;
`;

export const SToastErrorIcon = styled(ExclamationCircleIcon)`
    fill: #ffffff;
    width: 20px;
    height: 20px;
    margin-left: 16px;
    margin-right: 12px;
`;

export const SToastSuccessIcon = styled(CheckCircleIcon)`
    fill: #ffffff;
    width: 20px;
    height: 20px;
    margin-left: 16px;
    margin-right: 12px;
`;

export const SToastWrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 90px;
    right: 5%;
    min-width: 271px;
    z-index: 100;
`;
