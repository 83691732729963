enum Role {
    ADMIN = "FiveTool-Admin",
    REDIRECTS = "FiveTool-Redirects",
    MARKETING = "FiveTool-Marketing",
    PHOTO_ALL = "FiveTool-Photo-All",
    PHOTO_MILB = "FiveTool-Photo-MiLB",
    BULK_PHOTO_ALL = "FiveTool-Photo-Bulk-Uploads-All",
    BULK_PHOTO_MILB = "FiveTool-Photo-Bulk-Uploads-MiLB",
    FORGE = "FiveTool-Forge",
    AB = "FiveTool-AB",
    VSM_REPORTING = "FiveTool-VSM-Reporting",
    PLAYER_MUSIC = "FiveTool-Player-Music",
    P2P = "P2P-Redirects",
    USERS = "FiveTool-Users",
}

export default Role;
