import { oktaAuth } from "../../okta";

import {
    SHeaderContainer,
    SHeaderInnerContainer,
    SLogoContainer,
    SUnstyledLink,
    SSignOutButton,
    SHeaderLogoNameContainer,
    STestHeaderContainer,
} from "./styles";

const appHeader = (appTitleText: string, onSignOutClick: () => void) => {
    return (
        <SHeaderInnerContainer>
            <SHeaderLogoNameContainer>
                <SUnstyledLink to="/">
                    <img
                        data-testid="header-logo"
                        alt="MLB Logo"
                        src="https://www.mlbstatic.com/team-logos/league-on-dark/1.svg"
                    />
                </SUnstyledLink>
                <SUnstyledLink to="/">
                    <SLogoContainer>{appTitleText}</SLogoContainer>
                </SUnstyledLink>
            </SHeaderLogoNameContainer>
            <SSignOutButton onClick={onSignOutClick}>Sign out</SSignOutButton>
        </SHeaderInnerContainer>
    );
};

type HeaderParams = {
    isTestEnv: boolean;
};
const Header = ({ isTestEnv }: HeaderParams) => {
    const onSignOutClick = () => oktaAuth.signOut();

    return (
        <>
            {isTestEnv ? (
                <STestHeaderContainer>
                    {appHeader("Five Tool QA", onSignOutClick)}
                </STestHeaderContainer>
            ) : (
                <SHeaderContainer>
                    {appHeader("Five Tool", onSignOutClick)}
                </SHeaderContainer>
            )}
        </>
    );
};

export default Header;
