import React, {
    createContext,
    useContext,
    useReducer,
    useEffect,
    PropsWithChildren,
} from "react";
import { OktaAuth } from "@okta/okta-auth-js";

import { useOktaAuth } from "@okta/okta-react";
import { TeamListItem, TToast } from "../../types";
import { fetchAllTeams } from "../utils";

import { appReducer } from "./reducer";

export type AppContextState = {
    userInfo?: Record<string, unknown>;
    teamsList?: TeamListItem[];
    toasts?: TToast[];
};

export const initState = { teamsList: null, userInfo: undefined, toasts: [] };

export const AppContext = createContext<{
    state: AppContextState;
    dispatch: React.Dispatch<any>;
}>({
    state: initState,
    dispatch: () => null,
});

export type AppProviderProps = PropsWithChildren<{
    oktaAuth?: OktaAuth;
}>;

const loadUserInfo = async (oktaAuth, dispatch, retryCount = 0): Promise<void> => {
    try {
        const userInfo = await oktaAuth.token.getUserInfo();
        dispatch({
            type: "SET_USER_INFO",
            payload: {
                userInfo,
            },
        });
    } catch (e) {
        if (e.message === "getUserInfo requires an access token object") {
            dispatch({
                type: "SET_USER_INFO",
                payload: {
                    userInfo: null,
                },
            });
            return;
        }

        if (retryCount < 30) {
            setTimeout(async () => {
                await loadUserInfo(oktaAuth, dispatch, retryCount + 1);
            }, 500);
            return;
        }

        dispatch({
            type: "ADD_TOAST",
            payload: { type: "error", text: e.message },
        });
    }
};

export const AppProvider = ({ children, oktaAuth }: AppProviderProps) => {
    const { authState } = useOktaAuth();
    const [state, dispatch] = useReducer(appReducer, initState);

    useEffect(() => {
        if (!authState.isAuthenticated) {
            return;
        }

        if (!state.userInfo) {
            loadUserInfo(oktaAuth, dispatch);
        }
    }, [authState.isAuthenticated]);

    useEffect(() => {
        if (!state.teamsList) {
            fetchAllTeams().then((data) =>
                dispatch({
                    type: "SET_TEAMS_LIST",
                    payload: { teamsList: data },
                }),
            );
        }
    }, [state.teamsList]);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error("useAppContext must be used within AppProvider");
    }

    return context;
};
