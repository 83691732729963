import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

import PATHS from "./configs/pathhelper";

// This needs to be set outside the scope of createUploadLink
const mlbEnvHeader = PATHS.GRAPH_VARIANT;
const apolloClientNameHeader = "fivetool-" + mlbEnvHeader;
const dataGraphUrl = `${PATHS.API}/proxy/graphql`;

const link = createUploadLink({
    headers: {
        "mlb-env": mlbEnvHeader,
        "apollographql-client-name": apolloClientNameHeader,
        target: "data-graph-gateway",
    },
    uri: dataGraphUrl,
});

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
});
