import { useEffect } from "react";

import { useAppContext } from "../../context/AppContext";
import { TOAST_INTERVAL } from "../../constants";
import {
    SToastContainer,
    SToastExitBtn,
    SToastExitIcon,
    SToastText,
    SToastErrorIcon,
    SToastInfoIcon,
    SToastSuccessIcon,
    SToastInnerContainer,
    SToastWrapper,
} from "./styles";

function GlobalToastContainer() {
    const { state, dispatch } = useAppContext();

    useEffect(() => {
        let intervalId = null;

        if (state.toasts.length === 0 && intervalId) {
            return;
        }

        if (state.toasts.length !== 0) {
            intervalId = setInterval(() => {
                dispatch({ type: "RESET_TOAST", payload: 0 });
            }, TOAST_INTERVAL);
        }

        return () => clearInterval(intervalId);
    }, [state.toasts]);

    if (!state.toasts || state.toasts.length === 0) return null;

    return (
        <>
            <GlobalToastWrapper>
                {state.toasts.map(({ text, type }, index) => (
                    <GlobalToast
                        key={`gt-${type}-${index}`}
                        type={type}
                        text={text}
                        index={index}
                    />
                ))}
            </GlobalToastWrapper>
        </>
    );
}

function GlobalToastWrapper({ children }) {
    return <SToastWrapper>{children}</SToastWrapper>;
}

export type TToastType = "success" | "info" | "error";

type Props = {
    type: TToastType;
    text: string;
    index: number;
};

function GlobalToast({ type, text, index }: Props) {
    const { dispatch } = useAppContext();
    const IconsByType = {
        success: SToastSuccessIcon,
        info: SToastInfoIcon,
        error: SToastErrorIcon,
    };
    const Icon = IconsByType[type];

    const resetToast = () => dispatch({ type: "RESET_TOAST", payload: index });

    return (
        <SToastContainer type={type}>
            <SToastInnerContainer>
                <Icon />
                <SToastText>{text}</SToastText>
            </SToastInnerContainer>
            <SToastExitBtn onClick={resetToast}>
                <SToastExitIcon />
            </SToastExitBtn>
        </SToastContainer>
    );
}

type TToastProps = {
    type: TToastType;
    text: string;
};

export function Toast({ type, text }: TToastProps) {
    const { dispatch } = useAppContext();

    useEffect(() => {
        dispatch({
            type: "ADD_TOAST",
            payload: { type, text },
        });
    }, []);

    return null;
}

GlobalToast.Wrapper = GlobalToastWrapper;
GlobalToast.Container = GlobalToastContainer;
GlobalToast.Item = Toast;

export default GlobalToast;
