import styled from "styled-components";

export const SWrapper = styled.div`
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding-top: 75px;
`;

export const SHeading1 = styled.h1`
    margin: 16px 0px;
`;

export const SHeading2 = styled.h2`
    margin: 16px 0px;
`;
